import React from 'react'
import SEO from 'components/SEO/SEO'
import {
  ContentSection,
  ContentTitle,
  FieldSection,
  FieldParagraph,
} from 'components/StyledComponents/StyledComponents'

const EditorialBoardPage = () => (
  <>
    <SEO title="Editorial Board" />
    <ContentSection>
      <ContentTitle>Editorial Board</ContentTitle>
      <FieldSection>
        <FieldParagraph listItem>
        Maria Nijnik, Professor, The James Hutton Institute, UK
        </FieldParagraph>
        <FieldParagraph listItem>
        Thomas Resl, DI, The Federal Institute of Agricultural Economics, Austria
        </FieldParagraph>
        <FieldParagraph listItem>
        Francesco Contò, Professor, Università di Foggia, Italy
        </FieldParagraph>
        <FieldParagraph listItem>
        Piermichele La Sala, Professor, Università di Foggia, Italy
        </FieldParagraph>
        <FieldParagraph listItem>
        Adam Cox, PhD, University of Portsmouth, UK
        </FieldParagraph>
        <FieldParagraph listItem>
        Gianpiero Torrisi, Professor, University of Catania, Italy
        </FieldParagraph>
        <FieldParagraph listItem>
        Tomas Doucha, Professor, Institute of Agricultural Economics and Information, Czech Republic
        </FieldParagraph>
        <FieldParagraph listItem>
        Walenty Poczta, Professor, Poznań University of Life Sciences, Poland
        </FieldParagraph>
        <FieldParagraph listItem>
        Drago Cvijanovic, Professor, University of Kragujevac, Serbia
        </FieldParagraph>
        <FieldParagraph listItem>
        Alexandru Stratan, Professor, National Institute for Economic Research, Moldova
        </FieldParagraph>
        <FieldParagraph listItem>
        Dimitre Nikolov, Professor, Institute of Agricultural Economics, Bulgaria
        </FieldParagraph>
        <FieldParagraph listItem>
        Ahmet Ali Koç, Professor, Akdeniz University, Turkey
        </FieldParagraph>
        <FieldParagraph listItem>
        Rasa Melnikiene, Professor, Lithuanian Institute of Agrarian Economics, Lithuania
        </FieldParagraph>
        <FieldParagraph listItem>
        Andrew F. Fieldsend, PhD, Research Institute of Agricultural Economics, Hungary
        </FieldParagraph>
        <FieldParagraph listItem>
        Zbigniew Floriańczyk, PhD, Institute of Agricultural and Food Economics – National Research Institute, Poland
        </FieldParagraph>
        <FieldParagraph listItem>
        Andrzej Kowalski, Prof. Emerit., Warsaw School of Economics, Poland
        </FieldParagraph>
        <FieldParagraph listItem>
        Jonel Subić, Professor, Institute of Agricultural Economics, Serbia
        </FieldParagraph>
        <FieldParagraph listItem>
        Jerzy Bański, Professor, Stanisław Leszczycki Institute of Geography and Spatial Organization Polish Academy of Sciences, Poland
        </FieldParagraph>
        <FieldParagraph listItem>
        Vera Majerova, Professor, Czech University of Life Sciences, Czech Republic
        </FieldParagraph>
        <FieldParagraph listItem>
        Olena Borodina, Professor,  Institute for Economics and Forecasting, National Academy of Sciences of Ukraine
        </FieldParagraph>
        <FieldParagraph listItem>
        Marian Podstawka, Professor, Institute of Agricultural and Food Economics – National Research Institute, Poland
        </FieldParagraph>
        <FieldParagraph listItem>
        Olga Varchenko, Professor, Bila Tserkva National Agrarian University, Ukraine
        </FieldParagraph>
        <FieldParagraph listItem>
        Marcin Wójcik, Professor, University of Łódź, Poland
        </FieldParagraph>
      </FieldSection>
    </ContentSection>
  </>
)

export default EditorialBoardPage
